import "twin.macro";

const Logo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 161 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M139.495.676V.71c2.469.547 2.147 1.873 2.147 3.983v14.775c0 2.444 0 3.12-2.463 3.985v.032h5.099V.676h-4.783ZM161 15.56h-.03c-1.097 4.11-2.19 7.26-7.565 7.26h-3.563V11.728h1.844c2.751 0 3.155 1.766 4.5 4.993h.031V6.041h-.031c-1.345 3.246-1.749 5.012-4.5 5.012h-1.844V1.35h3.563c5.062 0 6.074 2.86 7.094 6.62h.033V.676h-15.689v22.809H161V15.56ZM114.764.676h-3.896l-.811.034c2.22.512 2.679 1.56 2.679 3.693v15.065c0 2.444-.304 3.12-2.679 3.985l.811.032h4.486V.676h-.59Zm19.149 18.985-2.97-5.749c-4.407.625-6.666.097-7.696-1.382 7.408-.063 10.326-2.15 10.326-6.199 0-4.048-3-5.655-10.629-5.655h-7.016v22.809h7.796v-.032c-2.217-.867-3-1.541-3-3.985V12.53h.826l5.439 10.955h8.628l1.725-.032c-1.333-1.026-2.43-1.863-3.429-3.792Zm-13.199-8.58V1.804c3.22.065 4.814 1.027 4.814 4.528 0 3.502-1.594 4.685-4.814 4.75ZM.677.676V.71c2.469.547 2.15 1.873 2.15 3.983v14.775c0 2.444 0 3.12-2.463 3.985v.032h5.099V.676H.677ZM22.188 15.56h-.03c-1.094 4.11-2.188 7.26-7.566 7.26H11.03V11.728h1.843c2.752 0 3.159 1.766 4.5 4.993h.034V6.041h-.027c-1.342 3.246-1.75 5.012-4.5 5.012h-1.844V1.35h3.562c5.066 0 6.074 2.86 7.098 6.62h.03V.676H6.034v22.809h16.154V15.56ZM44.737.71c3.061.675 4.556 2.248 4.556 5.269V19.18L36.389.676h-3.077l11.644 16.595 4.346 6.214h.653V5.979c0-3.021 1.096-4.595 3.313-5.269h-8.53ZM32.073.676h-6.928V.71c2.218.547 3.31 1.508 3.31 3.983v13.814c0 3.433-1.092 4.4-3.31 4.946v.032h9.157v-.032c-3.781-.901-5.187-2.025-5.187-4.946V4.82l13.22 18.664h6.271L32.656.676h-.583ZM83.52 12.155c0 6.695 4.917 11.904 10.949 11.907 6.031 0 10.933-5.212 10.933-11.907C105.402 5.461 100.506.1 94.469.1 88.446.102 83.52 5.46 83.52 12.155Zm16.676.088c0 6.744-1.688 11.048-5.721 11.048-4.034 0-5.725-4.297-5.725-11.048C88.75 5.27 90.435.87 94.469.87c4.033 0 5.727 4.39 5.727 11.373Z"
      fill="currentColor"
      shapeRendering="auto"
    />
    <path
      d="M108.539 12.243c0-6.22-3.803-10.281-9.537-11.639 4.024 1.945 6.843 6.389 6.843 11.548 0 4.95-2.597 9.245-6.36 11.301 5.464-1.423 9.054-5.318 9.054-11.21Zm-25.465-.088c0-5.15 2.809-9.584 6.815-11.548-5.7 1.374-9.479 5.428-9.479 11.626 0 5.871 3.566 9.76 8.999 11.199-3.75-2.054-6.335-6.34-6.335-11.277Zm-26.516-.028a12.738 12.738 0 0 1 2.24-7.217A12.203 12.203 0 0 1 64.686.348a15.8 15.8 0 0 0-1.024.16c-.136.03-.361.093-.607.165-.479.122-.951.269-1.416.44h-.033c-4.452 1.654-7.368 5.34-7.368 11.133 0 6.31 3.663 10.062 9.048 11.33.437.115.862.199 1.245.261a12.221 12.221 0 0 1-5.774-4.57 12.75 12.75 0 0 1-2.198-7.14Z"
      fill="currentColor"
      shapeRendering="auto"
    />
    <path
      d="M68.435.87c2.782 0 5.467 1.607 8.097 9.675h.03V.45a2.09 2.09 0 0 1-1.822.868c-1.716 0-3.462-1.092-6.505-1.208a11.546 11.546 0 0 0-7.832 3.529 12.175 12.175 0 0 0-3.399 8.063 12.218 12.218 0 0 0 2.854 8.285c1.935 2.292 4.642 3.746 7.578 4.07h.27c3.377 0 4.972-1.316 7.034-1.316.35-.021.7.047 1.017.199.318.15.594.38.805.669v-9.676h-.03c-1.063 5.618-4.222 9.363-8.097 9.363-4.252 0-5.883-4.681-5.883-11.048 0-6.56 1.631-11.38 5.883-11.38Z"
      fill="currentColor"
      shapeRendering="auto"
    />
  </svg>
);

export default Logo;
