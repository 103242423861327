/* eslint-disable unicorn/no-null */
import React, {
	useState,
	useEffect,
	SetStateAction,
	Dispatch,
} from "react";
import { StaticQuery, graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const PopUpContainer = styled.div`
	${tw`bg-beige text-navy`};
	${tw`w-full max-w-sm md:(w-1/2 mx-auto) lg:(w-2/5 mr-6) xl:(w-1/3)`};
	${tw`fixed z-50 bottom-0 right-0`};
	${tw`flex flex-col`};
	${tw`rounded-t`}
	box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.35);
`;

const Button = styled.button`
	${tw`flex justify-center items-center text-sm w-48 uppercase text-beige-light tracking-wider font-bold  py-2 px-3 bg-teal border border-teal transition duration-200 hocus:(bg-beige text-teal)`}
`;

interface PopUpProps {
	title: string;
	body: string[];
	buttonLink: {
		label: string;
		url: string;
	};
	setOpen: Dispatch<SetStateAction<boolean>>;
}

const PopUp = ({
	title,
	body,
	buttonLink,
	setOpen,
}: PopUpProps) => {
	return (
		<PopUpContainer>
			<div tw="bg-teal text-beige-light w-full m-0 uppercase md:(text-lg) font-bold tracking-wide py-3 rounded-t flex items-center justify-between">
				<p tw="w-full pl-12 md:(pl-16)">{title}</p>
				<button
					type="button"
					tw="w-1/12 flex justify-end px-3.5"
					onClick={() => setOpen(false)}
				>
					<FontAwesomeIcon icon={faTimes} />
				</button>
			</div>

			<div tw="text-sm py-4 px-12 md:(py-4 px-16) text-teal">
				{body.map((text) => (
					<p tw="py-2" key={text}>
						{text}
					</p>
				))}
			</div>

			<div tw="px-12 md:(px-16) pb-8 flex">
				{buttonLink ? (
					<Button type="button">
						<a href={buttonLink.url}>{buttonLink.label}</a>
					</Button>
				) : null}
			</div>
		</PopUpContainer>
	);
};

const PopupModal = (): JSX.Element | null => {
	const [shown, setShown] = useState<boolean>(true);
	const [open, setOpen] = useState<boolean>(true);

	useEffect(() => {
		if (localStorage.getItem("hasVisited")) {
			setShown(true);
		} else {
			setShown(false);
			localStorage.setItem("hasVisited", "true");
		}
	}, []);

	return !shown && open ? (
		<StaticQuery
			query={graphql`
				query {
					modal: sanityModal {
						title
						body
						buttonLink {
							label
							url
						}
					}
				}
			`}
			render={(data) =>
				data.modal.title ? (
					<PopUp
						title={data.modal.title}
						body={data.modal.body}
						buttonLink={data.modal.buttonLink}
						setOpen={setOpen}
					/>
				) : null
			}
		/>
	) : null;
};

export default PopupModal;
