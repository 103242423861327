/* eslint-disable unicorn/no-null */
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import tw, { css } from "twin.macro";

interface MessageProps {
	name: string;
	textLink: {
		linkText: string;
		url: string;
	};
	buttonLink: {
		label: string;
		url: string;
	};
}

const Message = ({
	name,
	textLink,
	buttonLink,
}: MessageProps) => {
	const messageStyles = [
		tw`font-normal bg-teal text-beige`,
		tw`w-full flex justify-content[center] align-items[center]`,
		tw`py-3`,
		tw`drop-shadow-lg`,
		tw`text-sm`,
	];

	const buttonStyles = [
		tw`border bg-beige text-teal border-beige`,
		tw`cursor-pointer`,
		tw`text-xs font-bold tracking-wider uppercase`,
		tw`flex items-center justify-center`,
		tw`px-4 py-1`,
		tw`transition-all duration-200`,
		tw`hocus:(bg-teal text-beige)`,
	];

	return name ? (
		<div css={[messageStyles]}>
			<div tw="flex flex-col justify-around items-center mx-2">
				<p tw="pb-2">{name}</p>
				{buttonLink ? (
					<button type="button" css={[buttonStyles]}>
						<a href={buttonLink.url}>{buttonLink.label}</a>
					</button>
				) : null}
			</div>
		</div>
	) : null;
};

const Banner = (): JSX.Element => {
	return (
		<StaticQuery
			query={graphql`
				query {
					message: allSanityBanner {
						nodes {
							name
							buttonLink {
								label
								url
							}
						}
					}
				}
			`}
			render={(data) => {
				const deets = data.message.nodes[1];
				return deets ? (
					<Message
						name={deets.name}
						textLink={deets.textLink}
						buttonLink={deets.buttonLink}
					/>
				) : null;
			}}
		/>
	);
};

export default Banner;
