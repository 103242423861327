import React, { ReactElement, ReactNode } from "react";
import "../styles/globals.scss";
import { GlobalStyles } from "twin.macro";

import Header from "./Header";
import Footer from "./Footer";
import PopupModal from "./Popup";

const Layout = ({
	children,
	title,
	...rest
}: {
	children: ReactNode;
	title: string;
}): ReactElement => {
	return (
		<div {...rest}>
			<GlobalStyles />
			<Header siteTitle={title} />
			<main>
				{children}
				<PopupModal />
			</main>
			<Footer />
		</div>
	);
};

export default Layout;
