/* eslint-disable react/no-unknown-property */
import React, { useState } from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw, { styled } from "twin.macro";
import {
	faBars,
	faTimes,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";

import Banner from "./Banner";
import Logo from "./Logo";
import { ButtonBlue } from "../styles/AppStyles.styles.tw";

type MenuItem = {
	title: string;
	href: string;
	label: string;
	external?: boolean;
};

const Container = styled.div`
	${tw`w-full max-w-[86rem] mx-auto`};
	${tw`xl:(flex items-center justify-between)`};
`;

const Menu = styled.ul`
	${tw`w-full flex flex-col pt-12`};
	${tw`justify-center items-center space-y-6`};
	${tw`xl:(flex-row space-x-6 space-y-0 w-[fit-content] py-0)`};
	${tw`2xl:(space-x-8)`};
`;

const linkStyle = tw`text-xs text-teal font-bold uppercase tracking-wider transition duration-300 ease-in-out 2xl:(text-sm) hover:(text-navy)`;

const menu: MenuItem[] = [
	{
		title: `amenities`,
		href: `/amenities`,
		label: `Amenities`,
	},
	{
		title: `floor-plans`,
		href: `/floor-plans`,
		label: `Floor Plans`,
	},
	{
		title: `gallery`,
		href: `/gallery`,
		label: `Gallery`,
	},
	{
		title: `virtual-tour`,
		href: `/virtual-tour`,
		label: `Virtual Tour`,
	},
	{
		title: `neighborhood`,
		href: `/neighborhood`,
		label: `Neighborhood`,
	},
	{
		title: ``,
		href: `https://encorepgh.securecafe.com/residentservices/encore-apartments/userlogin.aspx`,
		external: true,
		label: `Resident Login`,
	},
];

const Item = ({
	title,
	href,
	label,
	external,
	siteTitle,
}: MenuItem & { siteTitle: string }) => {
	return external ? (
		<a
			href={href}
			target="_blank"
			rel="noreferrer"
			css={[linkStyle]}
		>
			{label}
		</a>
	) : (
		<Link
			to={href}
			css={[
				linkStyle,
				siteTitle === title && tw`underline text-rust`,
			]}
		>
			{label}
		</Link>
	);
};

function Header({
	siteTitle,
}: {
	siteTitle: string;
}): JSX.Element {
	const [nav, setNav] = useState(false);

	return (
		<>
			<header
				css={[
					tw`fixed z-30 w-full mx-auto font-sans bg-white shadow-md`,
				]}
			>
				<div tw="w-full mx-auto xl:(px-[5.2%]) 2xl:(px-0)">
					<Container css={[nav ? tw`pt-4 pb-0` : tw`py-4`]}>
						<div
							tw="flex items-center justify-between px-[5.2%] xl:(px-0)"
							css={[nav && tw`pb-4`]}
						>
							<Link
								to="/"
								tw="text-teal transition duration-300 ease-in-out flex h-6 hover:(text-navy)"
							>
								<Logo />
							</Link>
							<button
								tw="cursor-pointer xl:hidden z-40 focus:outline-none"
								onClick={() => setNav(!nav)}
								aria-label="Home"
								type="button"
							>
								{nav ? (
									<FontAwesomeIcon
										icon={faTimes}
										tw="text-2xl text-teal"
									/>
								) : (
									<FontAwesomeIcon
										icon={faBars}
										tw="text-2xl text-teal"
									/>
								)}
							</button>
						</div>
						<nav
							css={[
								tw`relative transition-all opacity-0 pointer-events-none bg-beige-light max-h-0 xl:max-h-96 xl:bg-transparent xl:opacity-100 xl:pointer-events-auto`,
								nav
									? tw`opacity-100 pointer-events-auto max-h-96 xl:pt-0`
									: ``,
							]}
						>
							<Menu>
								{menu.map((item) => (
									<li key={item.label}>
										<Item
											siteTitle={siteTitle}
											title={item.title}
											href={item.href}
											label={item.label}
											external={item.external}
										/>
									</li>
								))}

								<li tw="items-center space-x-4 hidden xl:(flex)">
									<a
										href="https://encorepgh.securecafe.com/onlineleasing/encore-apartments/guestlogin.aspx"
										target="_blank"
										rel="noopener noreferrer"
									>
										<ButtonBlue tw="hidden text-sm xl:(w-40 block)">
											APPLY NOW
										</ButtonBlue>
									</a>

									<a href="tel:+18332891263">
										<FontAwesomeIcon
											icon={faPhone}
											tw="text-xl text-teal transition duration-300 ease-in-out hover:(text-navy)"
										/>
									</a>
								</li>

								<li tw="w-full xl:(hidden)">
									<a
										href="https://encorepgh.securecafe.com/onlineleasing/encore-apartments/guestlogin.aspx"
										target="_blank"
										rel="noopener noreferrer"
										tw="w-full flex"
									>
										<p tw="w-full flex justify-center text-center py-2.5  text-beige-light bg-rust uppercase text-sm font-semibold transition duration-300 ease-in-out hover:(bg-beige-light text-rust)">
											Apply Now
										</p>
									</a>
								</li>
							</Menu>
						</nav>
					</Container>
				</div>
				<Banner />
			</header>
		</>
	);
}

export default Header;
